<template>
    <section>
        <transition-group v-if="modalArray" name="modal">
            <component
                v-for="modalComp in modalArray"
                :key="`modal-${modalComp.idComponent}`"
                :is="modalComp.component"
                :dismiss="modalComp.dismiss"
                :accept="modalComp.accept"
                :idComponent="modalComp.idComponent"
                :componentData="modalComp.props"
                :class="{ 'd-block': modalComp.component }"
            />
        </transition-group>
    </section>
</template>

<script>
import ModalService from "../../services/modal.service";
export default {
    data() {
        return {
            modalArray: []
        };
    },
    methods: {
        removeModal(pos) {
            this.modalArray.forEach(element => {
                if (element.idComponent == pos) {
                    this.modalArray.splice(this.modalArray.indexOf(element), 1);
                }
            });
        }
    },
    created() {
        ModalService.$on(
            ModalService.modalEnum.open,
            ({ component, props, resolve, reject, idComponent }) => {
                this.modalArray.push({
                    component,
                    props,
                    dismiss: pos => {
                        this.removeModal(pos);
                        resolve(pos);
                    },
                    accept: pos => {
                        this.removeModal(pos);
                        resolve(pos);
                    },
                    idComponent
                });
            }
        );
        ModalService.$on(ModalService.modalEnum.close, ({ idComponent }) => {
            this.removeModal(idComponent);
        });
    }
};
</script>
